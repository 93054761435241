import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Head from "../components/head"
import Header from "../components/header"
import Footer from "../components/footer"
import Nav from "../components/nav"
import styles from "../styles/global.css"
import security from "../styles/security.css"
import media from "../styles/media.css"
import { LoginHeader } from "../components/nav"
import { SocialHeader } from "../components/social"

// export default function Home() {
//   return <div>Hello world!</div>
// }
export function Banner_img() {
  return <StaticImage src="../../static/images/jobs-hd.jpg" alt="banner" />
}
export default () => (
  <>
    <Header titleText="Privacy - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
      </div>

      <Head />

      <div className="banner_contact">
        <img className="contact_img" src="/images/sky-crop.jpg" alt="banner" />
        <h1 className="bannerinfo">PRIVACY</h1>
      </div>

      <div className="security privacy">
        <div className="item">
          <div className="title">
            Welcome to Happy Mango. Your privacy is important to us.
          </div>
          <div className="excerpt">
            <p>
              This Privacy Policy covers how we collect, use, disclose,
              transfer, and store information that we receive when you use our
              Services (as defined below), including those who access some of
              our website content but do not have accounts (“Visitors”) and
              those who have subscribed to the Service (“Members”). This Privacy
              Policy applies only to those websites, services, and applications
              included within our Services and does not apply to any third-party
              websites, services or applications, even if they are accessible
              through our Services. Also, please note that, unless otherwise
              stated in this Privacy Policy, words used in this Privacy Policy
              have the same meanings as in our Terms ofService.
            </p>
            <p>
              By using the Services, you agree to this Privacy Policy and the
              processing of your data, including your personal information, in
              the manner provided in this Privacy Policy. If you do not agree to
              these terms, please do not use the Services.
            </p>
            <p>
              Happy Mango is an Internet-based website and mobile application
              (“app”) that, with your permission, aggregates financial
              information about you (interchangeably, with and without others,
              either separately or collectively, “you” or “user”) to generate a
              financial assessment about you (“Financial Profile”). Happy Mango,
              with your permission, then shares your Financial Profile and
              connects you with third-party financial institutions (all of the
              foregoing, the“Services”).
            </p>
            <p>
              This Privacy Policy (as amended from time to time, “Policy”)
              describes to you how and when Happy Mango Inc. (d/b/a Happy Mango
              or www.happymangocredit.com, and all subdomains) (“Happy Mango”
              “we,” “our,” or “us”) obtains, retains, discloses, transfers,
              disseminates, analyzes, and otherwise uses (“collect(s)”)
              information from you and others about you, and how we utilize or
              share such information. For example, we may collect such
              information when you use the Services through our website, our
              app, or another source, or when you contact us directly, such as
              by email, SMS (text messaging), or other form of contact or
              communication with us, or when third-parties provide us
              withinformation about you. Please note that, if you provide us
              with your telephone number, you agree to us sending you text
              notifications and understand that data usage rates may apply (to
              which you consent). By using the Services, you consent to us
              collecting your information as set forth in this Policy and in
              connection with the Services (as we provide them from time to
              time, which may differ at any time from the Services that we
              provide when you create, edit, or modify your account).
            </p>
          </div>
          <div className="title">Information we collect from you</div>
          <div className="excerpt">
            <h3>Basic account information</h3>
            <p>
              When you create or modify an account with us we may ask you to
              provide to us certain personally identifiable information (“
              <b>PII</b>”), such as your name (although pseudonyms are allowed),
              an email address, a password for your account, and we may also ask
              for your telephone number (separately or collectively, “
              <b>Basic Account Information</b>”). You may edit your Basic
              Account Information at any time.
            </p>
            <h3>Additional information that youprovide</h3>
            <p>
              In addition to Basic Account Information, we collect other
              information, depending on the specific needs and requirements for
              the services that we provide you. For example, you may upload
              certain documents, such as a pictureof a utility bill, for
              purposes ofapplying for a loan at one of the lenders on the Happy
              Mango platform. Also, if you contact us via email or contact us in
              any other way, we may collect the information that you send us or
              share with us in connection with such communication.
            </p>
            <h3>
              Information collected on behalf of partners to provide Services
            </h3>
            <p>
              We collect additional personally identifiable information on
              behalf of our partners, for example we will ask for social
              security number when applying for a loan from a partner financial
              institution.
            </p>
            <h3>Automatically collected information</h3>
            <p>
              When you use the Services, we automatically collect some
              information, such as the following (some of which may be the same
              or similar to Basic Account Information or additional information
              that we previously collected):
            </p>
            <p></p>
            <ul>
              <li>
                Device Information: Information about your computer, mobile, or
                other device, including its model number, operating system and
                version, mobile carrier, telephone number, browser type and
                language, and any unique device identifiers, such as the
                devices’ MAC address orIMEI.
              </li>
              <li>
                Location Information: With your consent, information about your
                location by using data from the device you use for the Services,
                such as precise location information from GPS enabled on your
                smartphone, information about wireless networks or cell towers
                near your device, or the Internet Protocol (IP) address of
                yourdevice(s).
              </li>
              <li>
                Cookies and Other Tracking Technologies: We use cookies
                andsimilar technologies, such as web beacons, to collect
                additional Services usage data, such as to track how users
                engage with or otherwise use the Services. We may use both
                session cookies and persistent cookies to better understand how
                you interact with the Services, to monitor aggregate usage by
                users (and, perhaps, users of other service providers) and web
                traffic routing in connection with our Services. Most Internet
                browsers automatically accept cookies. Although you can change
                your web browser settings and instruct it to stop accepting
                cookies or to prompt you before accepting a cookie from the
                websites you visit, some of the Services may not function
                properly if you disable cookies.
              </li>
              <li>
                Usage: We analyze your use of the Services, such as the time,
                date, and location of your use, and the third-party financial
                institutions with which you connect through theServices.{" "}
              </li>
              <li>
                Log Data: When you use and interact with the Services, we may
                receive and record information such as your IP address, browser
                type, operating system, browser history, location, mobile
                carrier, device information (including device and application
                IDs), search terms, and cookie information. We may also receive
                this informationwhen you click on, view or interact with links
                on our Services, including links to third-party websites or
                applications, or when you receive email notifications fromus.
              </li>
            </ul>
            <p></p>
          </div>
          <div className="title">Information we collect from you</div>
          <div className="excerpt">
            <p>
              We may collect information about you that we obtain from other
              third parties.For example, if you link your Happy Mango account by
              using the “add account” feature of the Services with one or more
              of your accounts with another service or financial institution
              (for example, Bank of America to link your checking account), we
              may receive information about you from that other service or
              institution. We may then combine any or all of this information
              with other information that we have collected from you or from
              other third parties. We may also collect information from
              financial institutions with which our Services are used or
              integrated (which such use or integration is with your
              permission), including tracking data concerning their use of the
              Services. We will not, however, retain your credit cardor your
              personal banking information.
            </p>
          </div>

          <div className="title">
            Third-Party applications that collectyour information through Happy
            Mango
          </div>
          <div className="excerpt">
            <h3>Google Analytics</h3>
            <p>
              Happy Mango uses a tool called “Google Analytics” to collect
              information about use of this site. Google Analytics uses
              “cookies,” which are small text files placed on your computer to
              collect standard internet log information and behavior information
              in an anonymous form. These cookies could collect information such
              as how often users visit this site, what pages they visit when
              they do so, and what other sites they used prior to coming to this
              site. Happy Mango uses the information we get from Google
              Analytics only to improve this site, by sending cookie information
              to Google to process and compile statistical reports on website
              activity. Google Analytics collects only the IP address assigned
              to you on the date you visit this website or app. It does not
              collect your name or other identifying information. Happy Mango
              does not combine the information it gets through Google Analytics
              with personally identifiable information. Although Google
              Analytics plants a permanent cookie on your web browser to
              identify you as a unique user the next time you visit the website
              or app, the cookie cannot be used by anyone but Google. Google’s
              ability to use and share information collected by Google Analytics
              about your visits to this site is restricted by the{" "}
              <a rel="" href="javascript:;">
                Google Analytics Terms of Use
              </a>{" "}
              and the{" "}
              <a rel="" href="javascript:;">
                Google Privacy Policy
              </a>
              . You can prevent Google Analytics from recognizing you on return
              visits to this site by{" "}
              <a rel="" href="javascript:;">
                disabling cookies
              </a>{" "}
              on yourbrowser.
            </p>
            <h3>Plaid</h3>
            <p>
              We use Plaid, an API for banking data that allows us to integrate
              with the infrastructure of various banks. When using Plaid, you
              provide your account information directly toPlaid. The account
              information you enter does not go through Happy Mango’s server. In
              compliance with Plaid’s policies, we are providing a link to
              Plaid’s privacy policy:{" "}
              <a
                rel=""
                href="https://plaid.com/legal/#Privacy-Policy"
                target="_blank"
              >
                https://plaid.com/legal/#Privacy-Policy
              </a>
              . As directedby Plaid, we state that each end user acknowledges
              and agrees that information will be treated in accordance with
              Plaid’s policy. You are notified and agree to Plaid’s policy prior
              to using our Services in a way that uses Plaid’s service. In
              addition, yougrant Plaid the right, power and authority to access
              and transmit the data on your behalf as reasonably necessary for
              Plaid to provide itsservices.
            </p>
            <h3>Dwolla</h3>
            <p>
              In order to use the payment functionality of our applicationas
              related to the disbursement and repayment of a loan, you must open
              a "Dwolla Platform" account provided by Dwolla, Inc. and you must
              accept the{" "}
              <a
                rel=""
                href="https://www.dwolla.com/legal/tos/"
                target="_blank"
              >
                Dwolla Terms of Service
              </a>{" "}
              and{" "}
              <a
                rel=""
                href="https://www.dwolla.com/legal/privacy/"
                target="_blank"
              >
                Privacy Policy
              </a>
              . You authorize us to collect and share with our payments provider
              Dwolla, Inc. your personal information including full name, date
              of birth, social security number, physical address, email address
              and financial information, and you are responsible for the
              accuracy and completeness of that data.
            </p>
          </div>

          <div className="title">
            What we do with the information that we collect
          </div>
          <div className="excerpt">
            <p>
              After we obtain information about you, here is what we do with it:
            </p>
            <ul>
              <li>
                We do NOT sell, rent, or trade your personal information (such
                as your Financial Profile) with any other party (including
                third-party financial institutions) without your permission and
                consent or as otherwise required bylaw.
              </li>
              <li>
                We may use your information to learn about your use of the
                Services so that we try to improve the features and functions of
                theServices.
              </li>
              <li>
                We may contact you to send you periodic
                messages,updates,ornotifications regarding the Services or this
                Policy.
              </li>
              <li>
                We may monitor and analyze trends and usage, and make inferences
                regarding the Services.
              </li>
              <li>
                We may provide you with custom content within the interface of
                the Services, which may include something such as a summary of
                your financialprofile.
              </li>
              <li>
                We may use your information to help prevent spam, fraud, or
                abuse by users of the Services.
              </li>
              <li>
                We may use your information to provide to financial or research
                institutions with which we have a relationship certain
                aggregated data (that will not personally identify you)
                regarding our users’ use of the Services as such use pertains
                specifically to such financial institution to help it better
                manage its use of our Services.
              </li>
              <li>
                We may use your information in a way that will not personally
                identify youto forecast trends, using algorithms or other
                methodologies, such as the success rate of certain Financial
                Profiles in obtaining loans or credit from the financial
                institutions with whom we connect you with.
              </li>
            </ul>
          </div>
        </div>
        <div className="item">
          <div className="title">&nbsp;</div>
          <p>
            See more by downloading our{" "}
            <a
              target="_blank"
              rel=""
              href="/Happy Mango Privacy Policy 2020.pdf"
            >
              Privacy Policy
            </a>
          </p>
          <p>&nbsp;</p>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
